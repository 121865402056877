<template>
  <div :class="[modClass ? `date-component-${modClass}` : '']">
    <label :for="id" v-if="label" class="label-field" v-html="label"></label>
    <div class="date-component__inner">
      <datepicker
        ref="datepicker"
        :id="id"
        startingView="day"
        :locale="locale"
        :inputFormat="dateFormat"
        v-model="date"
        :placeholder="placeholder"
        :style="{ background: !disabled ? '#fff' : null }"
        :clearable="clearable && !disabled"
        :disabled="disabled"
        :required="required"
        :class="[{ error: v$.date.$error || error }]"
        :typeable="typeable"
        :lowerLimit="lowerLimit"
        :upperLimit="upperLimit"
        :minimumView="minimumViewDate"
        position="center"
        @blur="blurHandler"
      />
      <svg
        v-if="dateIcon"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        class="svg"
        :class="{ svg_disabled: disabled }"
        @click="openCalendar"
      >
        <path
          d="M15.5009 2.5V7.5M19.0714 10.062V6.3C19.0714 5.57098 18.4958 4.98 17.7857 4.98H6.2143C5.50422 4.98 4.92859 5.57098 4.92859 6.3V10.062M19.0714 10.062V18.18C19.0714 18.909 18.4958 19.5 17.7857 19.5H6.2143C5.50422 19.5 4.92859 18.909 4.92859 18.18V10.062M19.0714 10.062H4.92859M8.50085 2.5V7.5"
          stroke-width="2"
        />
      </svg>
    </div>
  </div>
  <div class="invalid-msg" v-if="v$.date.$error">
    <template v-if="v$.date.required.$invalid">Поле обязательно для заполнения</template>
    <template v-else-if="v$.date.minValue.$invalid">
      Значение должно быть не ранее
      {{ $momentFormat(lowerLimit, 'DD.MM.YYYY') }}
    </template>
    <template v-else-if="v$.date.maxValue.$invalid">
      Значение должно быть не позднее
      {{ $momentFormat(upperLimit, 'DD.MM.YYYY') }}
    </template>
    <template v-else-if="v$.date.validDate.$invalid"> Неверный формат даты. </template>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import { maxValue, minValue, requiredIf } from '@vuelidate/validators';
  import { ru } from 'date-fns/locale';
  import moment from 'moment';

  import Constants from '@/common/constants';

  const dateFormat = 'DD.MM.YYYY';

  const validDate = (value) => {
    if (!value) return true;
    const m = moment(value, dateFormat);
    return m.isValid();
  };

  export default {
    props: {
      modelValue: String,
      clearable: {
        type: Boolean,
        default: true,
      },
      required: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      id: String,
      label: {
        type: String,
        default: '',
      },
      typeable: {
        type: Boolean,
        default: false,
      },
      lowerLimit: {
        type: Date,
        default: Constants.dateLowerLimit,
      },
      upperLimit: {
        type: Date,
        default: Constants.dateUpperLimit,
      },
      modClass: {
        type: String,
        default: '',
        validator: (value) =>
          ['redesigned', 'modal-request', 'redesign-type-exponential', 'filter-small', 'filter'].includes(value),
      },
      placeholder: {
        type: String,
        default: 'дд.мм.гггг',
      },
      dateIcon: {
        type: Boolean,
      },
      minimumViewDate: {
        type: String,
        default: 'day',
        validator: (v) => ['day', 'month', 'year'].includes(v),
      },
      dateFormatProps: {
        type: String,
        default: 'dd.MM.y', // bugfix: used 'y' instead of 'yyyy' because of padding year with zeroes when typing
      },
      error: {
        type: Boolean,
        default: false,
      },
    },
    created() {
      if (this.modelValue) this.date = this.modelValue ? new Date(Date.parse(this.modelValue)) : null;
    },
    data() {
      return {
        dateFormat: this.dateFormatProps, //"dd.MM.y", // bugfix: used 'y' instead of 'yyyy' because of padding year with zeroes when typing
        date: null,
        locale: ru,
      };
    },
    methods: {
      blurHandler(e) {
        this.date = e.target.value ? moment(e.target.value, dateFormat).toDate() : null;
      },
      openCalendar() {
        this.$refs.datepicker.inputRef.focus();
      },
    },
    watch: {
      modelValue(newValue) {
        this.date = newValue ? new Date(Date.parse(newValue)) : null;
      },
      date(newValue) {
        const hasNewValue = newValue != null && newValue != 'Invalid Date';
        if (this.dateFormatProps === 'y') {
          this.$emit('update:modelValue', hasNewValue ? moment(newValue).format('YYYY') : null);
        } else {
          this.$emit('update:modelValue', hasNewValue ? moment(newValue).format('YYYY-MM-DD') : null);
        }
      },
      disabled: function (newValue) {
        if (newValue) {
          this.date = null;
        }
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        date: {
          required: requiredIf(function () {
            return this.required;
          }),
          minValue: minValue(this.lowerLimit),
          maxValue: maxValue(this.upperLimit),
          validDate,
        },
      };
    },
  };
</script>

<style lang="scss">
  .date-component-redesigned {
    position: relative;
    display: flex;
    align-items: center;
    gap: 12px;

    .date-component__inner {
      position: relative;
      width: 100%;
    }

    .v3dp__datepicker {
      width: 100%;

      .v3dp__popout {
        padding-bottom: 12px;
      }

      .v3dp__heading__button {
        width: 24px;
      }

      .v3dp__heading__center {
        font: $fira-14-20;
        font-weight: 500;
      }

      .v3dp__heading__icon {
        width: 24px;
      }
      .v3dp__heading {
        padding: 16px 8px 8px 8px;
        height: auto;
        flex: 0;
      }
      .v3dp__body {
        font-family: 'Fira Sans';
        font-size: 12p;
        line-height: 24px;
      }
      .v3dp__subheading {
        margin-top: 8px;
      }

      .v3dp__divider {
        border-color: $grey;
        margin: 12px 0;
      }

      .v3dp__elements {
        button {
          span {
            padding: 4px;
            font-family: 'Fira Sans';
            font-size: 12px;
            line-height: 16px;
          }
        }
      }

      .v3dp__input_wrapper {
        input {
          padding: 8px 8px 8px 14px;
          font: $fira-14-20;
          border-radius: 4px;
          border: 1px solid $grey;
          background: $light-grey;
          height: 40px;

          &:focus {
            border-color: $green !important;
          }

          &.error {
            border-color: $red !important;
          }

          &:disabled {
            border: none;
            background: $grey-disabled !important;
          }

          &:disabled::after {
            opacity: 0;
          }
        }
        .v3dp__clearable {
          position: absolute;
          left: auto;
          mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m207.6 256 107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
          width: 10px;
          height: 18px;
          mask-position: center;
          mask-repeat: no-repeat;
          mask-size: contain;
          background-color: #999;
          top: 50%;
          transform: translateY(-50%);
          right: 15px;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: $black-st;
          }

          i {
            opacity: 0;
          }
        }
      }
    }

    .svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 8px;
      width: 24px;
      height: 24px;
      z-index: 2;
      cursor: pointer;
      stroke: $base;
    }
    .svg_disabled {
      stroke: $light-grey-5;
    }

    &.small {
      .v3dp__datepicker {
        .v3dp__input_wrapper {
          input {
            height: 40px;
          }
        }
      }
    }

    .filter-block-ves-events & {
      flex: 1 1;
    }

    &.popover-left {
      .v3dp__popout {
        right: auto;
        left: 0;
      }
    }
  }

  .date-component-modal-request,
  .date-component-filter {
    .date-component__inner {
      position: relative;
      width: 100%;
    }

    .svg {
      position: absolute;
      top: 7px;
      right: 8px;
      width: 24px;
      height: 24px;
      z-index: 2;
      cursor: pointer;
      stroke: $dark-grey-1;
    }

    .v3dp__input_wrapper {
      input {
        border-radius: 4px;
        padding: 9px 14px;
        font: $fira-14-20;

        &:focus {
          border-color: $green !important;
        }

        &.disabled,
        &:disabled {
          background: $grey-disabled !important;
          border-color: $grey-disabled !important;
        }

        &:focus {
          border-color: $grey;
        }

        &::placeholder {
          letter-spacing: 0;
        }
      }

      .v3dp__clearable {
        position: absolute;
        right: 10px;
        top: 11px;
        left: auto;
      }
    }

    .bulk-editing-popup & {
      flex: 1;
    }
  }

  .date-component-filter {
    &:not(:first-of-type) {
      margin-top: 12px;
    }
  }

  .v3dp__popout {
    right: 0;
    z-index: 11110 !important;

    .form-create-request & {
      bottom: 100%;
      right: 0;
      left: 0;
    }
  }

  .v3dp__input_wrapper {
    position: relative;
    background: #ffffff;
    border: 1px solid rgba(195, 195, 200, 0.5);
    input {
      background: #fbfbfb !important;
      width: 100%;
      display: inline-block;

      &.datepicker {
        &--rectangular {
          border-radius: 4px;
          padding: 9px 14px;
          font: $fira-14-20;

          &::placeholder {
            font-size: 16px;
            line-height: 24px;
          }

          &:focus {
            border-color: $grey;
          }
        }
      }
    }
    .v3dp__clearable {
      position: absolute;
      right: 10px;
      top: 6px;
      left: auto;
    }
  }

  .date-component {
    &-redesign-type-exponential {
      display: flex;
      align-items: center;
      position: relative;

      .label-field {
        margin: 0 12px 0 0;
      }

      .svg {
        stroke: black;
        position: absolute;
        right: 9px;
        bottom: 9px;
        cursor: pointer;
      }

      .date-component__inner {
        flex: 1 1 auto;
      }

      .v3dp {
        &__input_wrapper {
          input {
            border-radius: 4px;
            padding: 9px 14px;
          }
        }

        &__input {
          &_wrapper {
            .v3dp__clearable {
              right: 38px;
              top: 50%;
              transform: translateY(-50%);
              width: 18px;
              height: 18px;

              &::before {
                content: '';
                position: absolute;
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox=%270 0 320 512%27 fill='%23999999' xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z%27/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-size: contain;
                width: 13px;
                height: 17px;
                inset: 0;
                margin: auto;
                z-index: 1;
              }

              i {
                position: absolute;
                inset: 0;
                font-size: 0;
                z-index: 2;
              }

              &:hover {
                &::before {
                  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox=%270 0 320 512%27 fill='%23000000' xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z%27/%3E%3C/svg%3E");
                }
              }
            }
          }
        }
      }
    }
  }

  .height-40 .v3dp__datepicker .v3dp__input_wrapper input {
    height: 40px;
  }

  .date-component-filter-small {
    &:not(:first-child) {
      margin-top: 5px;
    }

    .horizontally-date & {
      margin: 0 !important;

      .v3dp__popout {
        right: 0;
        transform: translateX(50%);
      }
    }

    .date-component {
      &__inner {
        position: relative;
        width: 100%;

        .svg {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 8px;
          width: 12px;
          height: 12px;
          z-index: 2;
          cursor: pointer;
          stroke: $dark-grey-1;
        }
      }
    }

    .v3dp__input_wrapper {
      input {
        border-radius: 4px;
        padding: 8px;
        font: $fira-12;

        &.disabled,
        &:disabled {
          background: $grey-disabled !important;
          border-color: $grey-disabled !important;
        }

        &::placeholder {
          font: $fira-12;
        }
      }

      .v3dp__clearable {
        right: 21px;
        top: 50%;
        transform: translateY(calc(-50% - 1px));
        font-size: 12px;
        line-height: 1;
        color: $dark-grey-1;

        .table-container-base & {
          right: 6px;
        }
      }
    }
  }

  .bulk-editing-popup {
    .v3dp__popout {
      right: auto;
      left: 0;
      bottom: 100%;
    }
  }

  .date-component__inner {
    input::placeholder {
      overflow: visible;
    }
  }
</style>
